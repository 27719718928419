import { Box, Divider } from '@mui/material';
import { useCallback, useContext, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import Config from '../../../config';
import { ApiConfigV2 } from '../../../shared/apiConfigV2';
import { useQueryString } from '../../../utilities/hooks/useQueryString';
import AuditLogs from '../../components/AuditLogs/AuditLogs';
import SectionHeader from '../../components/SectionHeader/SectionHeader';
import TableViewComponent from '../../components/TableViewComponent/TableViewComponent';
import ConfirmDialog from '../../components/confirmDialog/ConfirmDialog';
import { LoadingView } from '../../components/loadingView';
import { RootViewContext } from '../RootView';
import { useGetAccesscodeRequests } from './hooks/useGetDomainRequests';
import { useGetRequestAccessCodes } from './hooks/useGetReqAccessCodes';
import { useUpdateRequests } from './hooks/useUpdateRequest';
import AccessCodeDialog from './showAccessCodesView';
import { AccessCodeRequestsGridColumns } from './utils/grid-columns/DomainRequestGridColumn';

const DefaultPageSize = 10;

const DomainRequestView = () => {
  const queryString = useQueryString({
    arrayFormat: 'separator',
    arrayFormatSeparator: ',',
  });
  const { setAppBreadCrumbs } = useContext(RootViewContext);
  const [pageSize, setPageSize] = useState<number>(
    queryString?.pageSize ? +queryString?.pageSize : DefaultPageSize,
  );
  const [page, setPage] = useState<number>(
    queryString?.page ? +queryString?.page : 0,
  );
  const { data, isLoading, refetch } = useGetAccesscodeRequests(page, pageSize);

  const [requestToApprove, setRequestToApprove] = useState<number | null>(null);
  const { data: accessCodesData, isLoading: isAccessCodesLoading } =
    useGetRequestAccessCodes(requestToApprove);

  const [showAccessCodeDropDown, setShowAccessCodeDropDown] =
    useState<boolean>(false);
  const [refetchAuditLogs, setRefetchAuditLogs] = useState(false);

  const handleRefetchAuditLogs = useCallback(() => {
    setRefetchAuditLogs(!refetchAuditLogs);
  }, [refetchAuditLogs, setRefetchAuditLogs]);

  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState<{
    open: boolean;
    msg: string;
    requestId: number | null;
  }>({ open: false, msg: '', requestId: null });

  const { mutateAsync, isLoading: isUpdating } = useUpdateRequests();

  /**
   * Handler for Updating the request based on type
   *
   * 1 -> Approve
   * 2 -> Reject
   * @param requestId
   * @param type
   * @returns
   */
  const handleAccessCodeRequest = async (requestId: number, type: string) => {
    try {
      if (!requestId || !type) return;
      if (type === '1') {
        setRequestToApprove(requestId);
        setShowAccessCodeDropDown(true);
      } else if (type === '2') {
        setShowDeleteConfirmation({
          open: true,
          msg: 'Are you sure you want to reject this request?',
          requestId: requestId,
        });
      }
    } catch (err) {
      toast.error('Error occurred while attempting to update request');
    }
  };

  const onRejectConfirm = async (requestId: number) => {
    try {
      await mutateAsync({
        reqId: requestId,
        status: 'rejected',
        accessCode: null,
        type: 'DOMAIN_REQUESTS',
      });
      toast.success('Request rejected successfully');
      refetch();
      handleRefetchAuditLogs();
    } catch (err) {
      toast.error('Error occurred while rejecting the request');
    } finally {
      setShowDeleteConfirmation({ open: false, msg: '', requestId: null });
    }
  };

  useEffect(() => {
    setAppBreadCrumbs([
      {
        label: 'Domain Requests',
        path: Config.paths.triaing.domainRequests,
        type: 'link',
      },
      {
        label: 'Domain Requests',
        type: 'text',
      },
    ]);
  }, []);

  if (isLoading) {
    return <LoadingView />;
  }

  return (
    <Box>
      <SectionHeader title={'Domain Requests'} showDivider />

      <TableViewComponent
        rows={data.membershipRequests ?? []}
        columns={AccessCodeRequestsGridColumns({ handleAccessCodeRequest })}
        pageSizeCustom={pageSize}
        currentPage={page}
        getRowId={(row: any) => row?.id}
        loading={isLoading}
        onPageSizeChange={(pageSize) => {
          setPageSize(pageSize);
        }}
        onPageChange={(page) => {
          setPage(page);
        }}
        paginationMode="server"
        total={data.total}
      />
      {showAccessCodeDropDown && requestToApprove && (
        <AccessCodeDialog
          open={showAccessCodeDropDown}
          accessCodes={accessCodesData}
          handleClose={() => setShowAccessCodeDropDown(false)}
          handleSuccess={() => {
            refetch();
            handleRefetchAuditLogs();
          }}
          reqId={requestToApprove}
          refetch={refetch}
        />
      )}
      <ConfirmDialog
        open={showDeleteConfirmation.open}
        handleClose={() =>
          setShowDeleteConfirmation({ open: false, msg: '', requestId: null })
        }
        onConfirm={() => {
          if (showDeleteConfirmation.requestId !== null) {
            onRejectConfirm(showDeleteConfirmation.requestId);
          }
        }}
        title="Confirm Rejection?"
        cancelButtonTitle="Cancel"
        confirmButtonTitle="Reject"
        disabled={isUpdating}
      >
        {showDeleteConfirmation.msg ||
          'Are you sure you want to reject this request'}
      </ConfirmDialog>
      <AuditLogs
        customUrl={ApiConfigV2.api.auditlog.getDomainRequestsAuditLogs}
        refetch={refetchAuditLogs}
      />
    </Box>
  );
};

export default DomainRequestView;
