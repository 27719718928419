import { MaterialIcons } from '../assets/MaterialIcons';

const paths = {
  login: '/login',
  home: '/file-upload',
  sso: '/sso',
  content: {
    root: '/content',
    home: '/content/home',
    course: '/content/learning-path',
    session: '/content/rescue',
    journals: '/content/journals',
    periodic: '/content/periodic',
    checkins: '/content/checkins',
    toolkit: '/content/toolkit',
    rewards: '/content/reward',
    questionnaire: '/content/questionnaire',
    scoring: '/content/scoring',
  },
  content_v2: {
    root: '/content-v2',
    home: '/content-v2/home',
    catalogue: '/content-v2/catalogue',
    course: '/content-v2/learning-path',
    session: '/content-v2/session',
    recommendation: '/content-v2/recommendations',
    emailTemplate: '/content-v2/email-templates',
    locales: '/content-v2/locales',
    collections: '/content-v2/collection',
    email: '/content-v2/emails',
    template: '/content-v2/emails/templates',
    emailOverride: '/content-v2/emails/override',
  },
  organisationV3: '/organisations-v3',
  marketing: {
    home: '/marketing',
    campaigns: '/campaigns',
  },
  provider: {
    home: '/provider',
    list: '/provider/list',
    session: '/provider/session',
    operations: '/provider/operations',
    meetings: '/provider/meetings',
  },
  inPerson: {
    home: '/in-person',
    location: '/in-person/location',
    room: '/in-person/room',
  },
  onsiteSession: {
    home: '/onsite-sessions',
    addNew: '/onsite-sessions/add-new',
    details: '/onsite-sessions/details',
  },
  triaing: {
    domainRequests: '/domain/requests',
    generalRequests: '/general/requests',
  },
  management: {
    home: '/management',
    billing: '/management/billing-v1',
    billingV2: '/management/billing',
    creditRequests: '/management/creditRequests',
    sendReports: '/management/sendReports',
    creditRefunds: '/management/creditRefunds',
    searchUser: '/users',
    searchUserById: '/users/:userId',
  },
  userManagement: {
    home: '/user-role-manegement',
  },
  caseNotes: {
    home: '/case-notes',
  },
  meeting: {
    details: '/meeting/details/:meetingId',
  },
  // settings: '/settings',
};

const Config = {
  paths,
  drawerWidth: 236,
  sidebar: [
    {
      title: 'Operations',
      path: paths.provider.operations,
      icon: MaterialIcons.Providers,
      children: [
        {
          title: 'Manage Clients',
          path: paths.organisationV3,
          icon: MaterialIcons.Organisation,
        },
        {
          title: '360° View',
          path: paths.provider.operations,
          icon: MaterialIcons.Leads,
        },

        {
          title: 'Provider List',
          path: paths.provider.list,
          icon: MaterialIcons.Providers,
        },
        {
          title: 'Provider Calendar',
          path: paths.provider.meetings,
          icon: MaterialIcons.CalendarIcon,
        },
        {
          title: 'Reporting',
          path: paths.management.sendReports,
          icon: MaterialIcons.SendReports,
        },
        {
          title: 'Manage Clinics',
          path: paths.inPerson.home,
          icon: MaterialIcons.InPerson,
        },
        {
          title: 'Case Notes',
          path: paths.caseNotes.home,
          icon: MaterialIcons.NoteIcon,
        },
      ],
    },
    {
      title: 'Content(CMS)',
      path: paths.content_v2.home,
      icon: MaterialIcons.Collections,
      // children: ,
    },
    {
      title: 'Support',
      path: paths.management.searchUser,
      icon: MaterialIcons.Collections,
      children: [
        {
          title: 'Manage users',
          path: paths.management.searchUser,
          icon: MaterialIcons.Search,
        },
        {
          title: 'Credit Requests',
          path: paths.management.creditRequests,
          icon: MaterialIcons.CreditRequests,
        },
        {
          title: 'Domain Requests',
          path: paths.triaing.domainRequests,
          icon: MaterialIcons.DomainRequests,
        },
        {
          title: 'General Requests',
          path: paths.triaing.generalRequests,
          icon: MaterialIcons.DomainRequests,
        },
      ],
    },
    {
      title: 'Misc',
      path: paths.home,
      icon: MaterialIcons.Reports,
      children: [
        {
          title: 'Onsite Sessions',
          path: paths.onsiteSession.home,
          icon: MaterialIcons.B2B,
        },
        {
          title: 'Upload File',
          path: paths.home,
          icon: MaterialIcons.Reports,
        },
        {
          title: 'Marketing Campaigns',
          path: paths.marketing.campaigns,
          icon: MaterialIcons.Campaigns,
        },
        {
          title: 'Marketing Leads',
          path: paths.marketing.home,
          icon: MaterialIcons.Leads,
        },
        {
          title: 'App Rewards',
          path: paths.content.rewards,
          icon: MaterialIcons.Rewards,
        },
        {
          title: 'Manage Email templates',
          path: paths.content_v2.email,
          icon: MaterialIcons.Email,
        },
        {
          title: 'Staff Management',
          path: paths.userManagement.home,
          icon: MaterialIcons.PersonIcon,
        },
      ],
    },
  ],
};

export default Config;
