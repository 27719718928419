import { useContext, useEffect, useState } from 'react';
import { GenericDialog } from '../../../../../components/genericDialog';
import { OrganisationoDetailContext } from '../OrganisationDetailView';
import TableViewComponent from '../../../../../components/TableViewComponent/TableViewComponent';
import { MembersInMultipleTeamsColumns } from '../../../utils/grid-columns/MembersInMultipleTeamsColumns';
import StyledButton from '../../../../../widgets/styledButton/StyledButton';
import { MaterialIcons } from '../../../../../../assets/MaterialIcons';
import ConfirmDialog from '../../../../../components/confirmDialog/ConfirmDialog';
import { GridCallbackDetails, GridRowSelectionModel } from '@mui/x-data-grid';
import { OrganisationTeamMemberItem } from '../../../../../../shared/types/organisation/OrganisationTeamMemberItem';
import { OrganisationServiceV2 } from '../../../../../../shared/service/services_v2';
import toast from 'react-hot-toast';
import { Typography } from '@mui/material';

const MembersInMultipleTeamsDialog = ({
  open,
  onClose,
  orgId,
}: {
  open: boolean;
  onClose: () => void;
  orgId: any;
}) => {
  const { membersInMultipleTeams } = useContext(OrganisationoDetailContext);
  const [pageSize, setPageSize] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [showConfirmDeleteMembersDialog, setShowConfirmDeleteMembersDialog] =
    useState<boolean>(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [showOnlySelect, setShowOnlySelect] = useState(false);
  const [memberIds, setMemberIds] = useState([]);
  const [selectedMembers, setSelectedMembers] = useState<number[]>([]);
  const [canDelete, setCanDelete] = useState(false);

  const handleRowSelection = (newSelection: any) => {
    setSelectedMembers(newSelection);
  };

  useEffect(() => {
    const checkAllowDelete = async () => {
      const result = await allowDelete();
      setCanDelete(result);
    };
    checkAllowDelete();
  }, [selectedMembers]);

  const deleteSelectedMembers = async () => {
    let response = null;

    try {
      setConfirmLoading(true);
      response = await OrganisationServiceV2.deleteDuplicateMembersById(
        selectedMembers,
        orgId,
      );

      if (response && response.data.success) {
        toast.success('Selected members removed successfully!');
        onClose();
        setShowConfirmDeleteMembersDialog(false);
      }
    } catch (error) {
      toast.error(
        'An error occurred while trying to remove the selected members. Please try again.',
      );
    } finally {
      setMemberIds([]);
      setSelectedMembers([]);
      setConfirmLoading(false);
      setShowOnlySelect(false);
    }
  };

  const userIdCounts: { [userId: number]: number } = {};

  // membersInMultipleTeams.forEach(
  //   (item: Partial<OrganisationTeamMemberItem>) => {
  //     if (item.userId) {
  //       if (userIdCounts[item.userId]) {
  //         userIdCounts[item.userId]++;
  //       } else {
  //         userIdCounts[item.userId] = 1;
  //       }
  //     }
  //   },
  // );

  const allowDelete = async () => {
    // Create an object to store the occurrence of each userId for otmIds
    const selectedUserIdCounts: { [userId: number]: number } = {};
    if (selectedMembers.length > 0) {
      for (const member of selectedMembers) {
        const found = membersInMultipleTeams.find((mem) => mem.id === member);
        if (found && found.userId) {
          if (selectedUserIdCounts[found.userId]) {
            selectedUserIdCounts[found.userId]++;
          } else {
            selectedUserIdCounts[found.userId] = 1;
          }
        }
      }
    }

    for (const key in selectedUserIdCounts) {
      if (
        selectedUserIdCounts[key] > 1 &&
        selectedUserIdCounts[key] === userIdCounts[key]
      ) {
        return false;
      }
    }
    return true;
  };

  return (
    <>
      <GenericDialog
        noFullScreen
        handleClose={onClose}
        open={open}
        title="Duplicate Users"
      >
        {selectedMembers.length > 0 && (
          <>
            {!canDelete ? (
              <Typography color="error" variant="subtitle1" fontSize={14}>
                Warning: Do not select all users with same userId.
              </Typography>
            ) : (
              <StyledButton
                color="red"
                variant="ghost"
                startIcon={MaterialIcons.Delete}
                onClick={() => setShowConfirmDeleteMembersDialog(true)}
              >
                Remove selected members
              </StyledButton>
            )}
          </>
        )}

        <TableViewComponent
          columns={MembersInMultipleTeamsColumns}
          rows={membersInMultipleTeams}
          pageSizeCustom={pageSize}
          currentPage={Math.ceil(currentPage / pageSize)}
          onPageSizeChange={(pageSize) => {
            setPageSize(pageSize);
          }}
          onPageChange={(page) => {
            const skipTemp = page * pageSize;
            setCurrentPage(skipTemp);
          }}
          checkboxSelection={true}
          onRowSelection={handleRowSelection}
        />

        <ConfirmDialog
          onConfirm={deleteSelectedMembers}
          handleClose={() => setShowConfirmDeleteMembersDialog(false)}
          open={showConfirmDeleteMembersDialog}
          title="Remove Duplicate members?"
          disabled={confirmLoading}
        >
          Are you sure you want to remove {selectedMembers.length} duplicate
          member
          {memberIds?.length !== 1 ? 's' : ''} from the organisation?
        </ConfirmDialog>
      </GenericDialog>
    </>
  );
};

export default MembersInMultipleTeamsDialog;
