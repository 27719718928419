import { CssBaseline } from '@mui/material';
import React, {
  Dispatch,
  SetStateAction,
  createContext,
  useState,
} from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';

import Config from '../../config';
import ScrollToTop from '../components/scrollToTop/ScrollToTop';

const LoginView = React.lazy(() => import('././login/LoginView'));
const MarketingView = React.lazy(
  () => import('./organisationManagement/marketing'),
);
const MarketingCampaignView = React.lazy(
  () => import('./organisationManagement/marketing/campaign'),
);
const ProviderView = React.lazy(() => import('./provider'));
const ContentView = React.lazy(() => import('./content_v2/ContentView'));
const FileUploadView = React.lazy(() => import('./fileUpload'));
const SendReportView = React.lazy(
  () => import('./organisationManagement/SendReportView'),
);

// const BillingPlansList = React.lazy(
//   () => import('./organisationManagement/billing_v2/BillingPlansList')
// );
const SSOSuccessfulRedirect = React.lazy(
  () => import('./login/SSOSuccessfulRedirect'),
);

const SearchUserView = React.lazy(
  () => import('./userManagement/userinfo/userFindView'),
);
const InPersonView = React.lazy(() => import('./inPerson'));

const RewardsView = React.lazy(() => import('./userManagement/rewards'));

import { getUserCookie } from '../../utilities/Api';
import { Appbar } from '../components/appbar';
import { AuthenticatedRoute } from '../components/authenticatedRoute/AuthenticatedRoute';
import { LoadingView } from '../components/loadingView';
import Sidebar from '../components/sidebar';
import CaseNotesView from './case-notes';
import MeetingInfoView from './meeting-details/MeetingInfoView';
import OnsiteSessionsView from './onsite-sessions';
import OrganisationViewV3 from './organisationManagement/organisations_v3/OrganisationViewV3';
import DomainRequests from './triaging';
import UserRoleManagementView from './user-role-management/UserRoleManagementView';
import CreditRequestViewRevised from './userManagement/CreditRequestViewRevised';

export interface IBreadcrumbs {
  label?: string;
  path?: string;
  callback?: any;
  startIcon?: JSX.Element; // For callback type btn
  endIcon?: JSX.Element; // For callback type btn
  type?: 'link' | 'text' | 'callback';
}
export const RootViewContext = createContext<{
  minimizeNavbar: boolean;
  setMinimizeNavbar: Dispatch<SetStateAction<boolean>>;
  appBreadCrumbs: IBreadcrumbs[];
  setAppBreadCrumbs: Dispatch<SetStateAction<IBreadcrumbs[]>>;
}>({
  minimizeNavbar: false,
  setMinimizeNavbar: () => false,
  appBreadCrumbs: [],
  setAppBreadCrumbs: () => [],
});

const RootView = () => {
  const isLoggedIn = getUserCookie();
  const location = useLocation();
  const [minimizeNavbar, setMinimizeNavbar] = useState(true);
  const [appBreadCrumbs, setAppBreadCrumbs] = useState<IBreadcrumbs[]>([]);
  /**
   * Paths that do not require sidebars, drawers etc
   */
  const FullScreenPaths = [Config.paths.login];

  return (
    <RootViewContext.Provider
      value={{
        minimizeNavbar,
        setMinimizeNavbar,
        appBreadCrumbs,
        setAppBreadCrumbs,
      }}
    >
      <CssBaseline />
      <ScrollToTop />
      <div
        style={
          isLoggedIn
            ? {
                display: 'flex',
                minHeight: '100vh',
              }
            : {}
        }
      >
        {isLoggedIn && !FullScreenPaths?.includes(location?.pathname) ? (
          <>
            <Sidebar />
            <Appbar />
          </>
        ) : null}
        <main
          style={
            isLoggedIn
              ? {
                  flexGrow: 1,
                  minHeight: '100vh',
                  marginTop: '4rem',
                  padding: '2rem 4rem',
                }
              : {}
          }
        >
          <React.Suspense fallback={<LoadingView />}>
            <Switch>
              <Route path={Config.paths.login}>
                <LoginView />
              </Route>
              <Route
                path={Config.paths.sso}
                component={SSOSuccessfulRedirect}
              />
              <AuthenticatedRoute
                path={Config.paths.home}
                component={FileUploadView}
              />
              <AuthenticatedRoute
                path={Config.paths.content.rewards}
                component={RewardsView}
              />
              <AuthenticatedRoute
                path={Config.paths.content_v2.root}
                component={ContentView}
              />
              <AuthenticatedRoute
                path={Config.paths.marketing.home}
                component={MarketingView}
              />
              <AuthenticatedRoute
                path={Config.paths.marketing.campaigns}
                component={MarketingCampaignView}
              />
              <AuthenticatedRoute
                path={Config.paths.management.creditRequests}
                component={CreditRequestViewRevised}
              />

              <AuthenticatedRoute
                path={Config.paths.organisationV3}
                component={OrganisationViewV3}
              />
              {/* <AuthenticatedRoute
                path={Config.paths.management.billingV2}
                component={BillingPlansList}
              /> */}
              <AuthenticatedRoute
                path={Config.paths.management.sendReports}
                component={SendReportView}
              />
              <AuthenticatedRoute
                path={Config.paths.provider.home}
                component={ProviderView}
              />
              <AuthenticatedRoute
                path={Config.paths.inPerson.home}
                component={InPersonView}
              />
              <AuthenticatedRoute
                path={Config.paths.onsiteSession.home}
                component={OnsiteSessionsView}
              />
              <AuthenticatedRoute
                path={Config.paths.management.searchUserById}
                component={SearchUserView}
              />
              <AuthenticatedRoute
                path={Config.paths.management.searchUser}
                component={SearchUserView}
              />
              <AuthenticatedRoute
                path={Config.paths.userManagement.home}
                component={UserRoleManagementView}
              />
              <AuthenticatedRoute
                path={Config.paths.caseNotes.home}
                component={CaseNotesView}
              />
              <AuthenticatedRoute
                path={Config.paths.meeting.details}
                component={MeetingInfoView}
              />
              <AuthenticatedRoute
                path={Config.paths.triaing.domainRequests}
                component={DomainRequests}
              />
              <AuthenticatedRoute
                path={Config.paths.triaing.generalRequests}
                component={DomainRequests}
              />
              <Route path="/">
                <Redirect
                  to={isLoggedIn ? Config.paths.home : Config.paths.login}
                />
              </Route>
            </Switch>
          </React.Suspense>
        </main>
      </div>
    </RootViewContext.Provider>
  );
};

export default RootView;
